import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

var navs = document.querySelectorAll('.navto');
navs.forEach(x => x.addEventListener('click', function(e) {
    e.preventDefault();
    let navto = e.currentTarget.getAttribute('href').substr(1);
    document.getElementById(navto).scrollIntoView({behavior: "smooth"});

}));
